import React, { } from 'react'
import NavTabBar from '../components/navTabBar'
import FooterBlock from '../components/blocks/FooterBlock'
import BlockBox from '../components/blocks/BlockBox'
import TosBlock from '../components/blocks/TosBlock'
import { Screens } from '../App'

export interface IHomeProps {
  mobile: boolean,
}
function Tos({ mobile, }: IHomeProps) {
  return (
    <>
      <NavTabBar mobile={mobile} screen={Screens.Tos} />

      <BlockBox>
        <TosBlock order={0} mobile={mobile} />
        <FooterBlock order={1} mobile={mobile} />
      </BlockBox>
    </>
  )
}

export default Tos

import React, { } from 'react'
import { TouchableOpacity, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'
import Text from '../text'

interface IButtonProps {
  title: string | React.ReactNode,
  titleColor?: string,
  color?: string,
  fill?: boolean,
  onPress?: () => void,
}
function Button({ title, titleColor, color, fill, onPress, }: IButtonProps) {
  const { Colors, Fonts, } = useSelector((state: IStores) => state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  return (
    <>
      <TouchableOpacity
        onPress={onPress}
        style={{
          flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', padding: 16, borderRadius: 32,
          backgroundColor: fill ? (color || School.tone || Colors.lightBlue) : undefined, borderWidth: 2,
          borderColor: color || School.highlight || Colors.lightBlue,
        }}
      >
        <Text style={{
          color: titleColor || School.tone || Colors.lightBlue, fontSize: 16, fontWeight: Fonts.welterWeight,
          // textShadowOffset: { width: 0.5, height: 0.5, }, textShadowRadius: 8, textShadowColor: School.tone,
        }}>{title}</Text>
      </TouchableOpacity>
    </>
  )
}

export default Button

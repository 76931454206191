import React, { useRef, } from 'react'
import NavTabBar from '../components/navTabBar'
import BlockBox from '../components/blocks/BlockBox'
import RefererBlock from '../components/blocks/RefererBlock'
import { Screens } from '../App'

export interface IReferProps {
  mobile: boolean,
}
function Refer({ mobile, }: IReferProps) {
  const masterRef = useRef<any>(null)

  return (
    <>
      <NavTabBar mobile={mobile} screen={Screens.Refer} />

      <BlockBox masterRef={masterRef}>
        <RefererBlock order={0} mobile={mobile} />
      </BlockBox>
    </>
  )
}

export default Refer

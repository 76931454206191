import React, { useEffect, useState } from 'react'
import { View, DimensionValue, Linking, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Button from '../button'
import { appStoreListing, headlines, } from '../../utils/constants'
import ProgressBar from '../animated/ProgressBar'
import Text from '../text'
import { sendUtmMarketing } from '../../utils/services'

interface IInfoBlockProps {
  order: number,
  mobile: boolean,
  masterRef: any,
}
function InfoBlock({ order, mobile, masterRef, }: IInfoBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const [focused, setFocused] = useState<number | null>(null)
  const [referer, setReferer] = useState<string | null>(null)
  const [headline, setHeadline] = useState<number>(0)
  const [resetProgressBar, setResetProgressBar] = useState<boolean>(false)

  const useHeadlines = School.headlines ? School.headlines : headlines

  useEffect(() => {
    const updateString = () => {
      let nextHeadline = Math.floor(Math.random() * useHeadlines.length)
      while (headline === nextHeadline) nextHeadline = Math.floor(Math.random() * useHeadlines.length)
      setHeadline(nextHeadline)
      setResetProgressBar(!resetProgressBar)
    }
    const intervalId = setInterval(updateString, 7000)
    return () => clearInterval(intervalId)
  }, [headline, resetProgressBar, useHeadlines.length])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  }, [])

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%',}}>
        <View style={{flex: mobile ? 2 : 4, justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.white,}}>
          <View style={{position: 'absolute', width: '100%', height: '0%', justifyContent: 'center', alignItems: 'center', marginTop: 8, paddingHorizontal: Spacing.padding,}}>
            <Text style={{fontSize: Fonts.xl, fontWeight: Fonts.middleWeight, color: School.tone || Colors.lightBlue, textAlign: 'center',}}>{useHeadlines[headline]}</Text>
          </View>
        </View>
        <ProgressBar duration={7000} reset={resetProgressBar} mobile={mobile} />

        <View style={{flex: 8, flexDirection: mobile ? 'column' : 'row', paddingTop: 32, paddingHorizontal: Spacing.padding, backgroundColor: Colors.white,}}>
          <View style={{flex: 2, marginRight: mobile ? 0 : 32, justifyContent: 'center',}}>
            <Text style={{fontSize: Fonts.xl, fontWeight: Fonts.middleWeight, marginBottom: 8, color: School.tone || Colors.lightBlue,}}>How does it work?</Text>
            <Text style={{fontSize: Fonts.md, fontWeight: Fonts.welterWeight, color: School.tone || Colors.lightBlue,}}>Streetpass shows you all the {School.name ? 'other students' : 'people'} you pass by{School.name && ` on ${School.name.toUpperCase()} campus`} throughout your day. It gives you the opportunity to match with missed connections, friends of friends, and people of similar interests who go to all the same places you do.</Text>

            <View style={{marginVertical: 24,}}>
              <Button
                title={'Get the app'}
                onPress={async () => {
                  // if (masterRef.current) masterRef.current.scrollTop = 0
                  if (referer) await sendUtmMarketing(referer)
                  Linking.openURL(appStoreListing)
                }}
              />
            </View>
          </View>

          <View style={{flex: 3, flexDirection: 'row', paddingVertical: 32,}}>
            <View
              onPointerEnter={() => setFocused(0)}
              // onPointerLeave={() => setFocused(null)}
              style={{flex: focused === 0 ? (mobile ? 3 : 2) : 1, paddingHorizontal: 4,}}
            >
              <img
                src={require('../../assets/media/screenshot-1-min.png')}
                alt={''}
                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, overflow: 'hidden', backgroundColor: Colors.white,}}
              />
            </View>

            <View
              onPointerEnter={() => setFocused(1)}
              // onPointerLeave={() => setFocused(null)}
              style={{flex: focused === 1 ? (mobile ? 3 : 2) : 1, paddingHorizontal: 4,}}
            >
              <img
                src={require('../../assets/media/screenshot-2-min.png')}
                alt={''}
                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 8, overflow: 'hidden', backgroundColor: Colors.white,}}
              />
            </View>

            <View
              onPointerEnter={() => setFocused(3)}
              // onPointerLeave={() => setFocused(null)}
              style={{flex: focused === 3 ? (mobile ? 3 : 2) : 1, paddingHorizontal: 4,}}
            >
              <img
                src={require('../../assets/media/screenshot-4-min.png')}
                alt={''}
                style={{width: '100%', height: '100%', borderRadius: 8, overflow: 'hidden', objectFit: 'cover', backgroundColor: Colors.white,}}
              />
            </View>
          </View>

        </View>
      </View>
    </>
  )
}

export default InfoBlock

import { schoolConfigs, schools, } from './constants'

export const getSchoolUrlParam = (): keyof typeof schoolConfigs | null => {
  const params = new URLSearchParams(window.location.search)
  const pathSegments = window.location.pathname.split('/').filter(Boolean)
  for (const school of schools) {
    if (params.get(school) !== null) return school as keyof typeof schoolConfigs
  }
  for (const school of schools) {
    if (pathSegments.includes(school)) return school as keyof typeof schoolConfigs
  }
  return null
}

import React from 'react'
import { View, DimensionValue, Image, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'
import Text from '../text'
import { ReactComponent as StreetpassIcon, } from '../../assets/icons/streetpass.svg'

interface IFooterBlockProps {
  order: number,
  mobile: boolean,
}
function FooterBlock({ order, mobile, }: IFooterBlockProps) {
  const { Colors, Fonts, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%',}}>
        {/* <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 64,}}>
          <StreetpassIcon style={{width: Fonts.xxxxl, height: Fonts.xxxxl, fill: Colors.white,}} />

          {School.logo &&
            <Image
              source={School.logo}
              style={{width: Fonts.xxxxl, height: Fonts.xxxxl, top: 4,}}
              resizeMode={'contain'}
            />
          }
        </View> */}

          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 40,}}>
            <View style={{justifyContent: 'center', alignItems: 'center', marginBottom: 4,}}>
              <StreetpassIcon style={{width: 256, height: 256, fill: Colors.white,}} />
            </View>

            {School.logo &&
              <>
                <View style={{width: 16,}}/>
                <View style={{aspectRatio: 1/1, borderRadius: 190, justifyContent: 'center', bottom: 2, backgroundColor: School.highlight, overflow: 'hidden',}}>
                  <Image
                    source={School.logo}
                    style={{width: 190, height: 190,}}
                    resizeMode={'contain'}
                  />
                </View>
              </>
            }
          </View>

        <View style={{position: 'absolute', width: '100%', justifyContent: 'center', alignItems: 'center', bottom: 8,}}>
          <Text style={{fontSize: 12, fontWeight: Fonts.featherWeight, color: Colors.white,}}>© {new Date().getFullYear()} Streetpass | Kite App Inc | All rights reserved</Text>
        </View>
      </View>
    </>
  )
}

export default FooterBlock

import React, { useCallback, useEffect, useRef, } from 'react'
import { View, Animated, Easing, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'

interface IProgressBarProps {
  duration: number,
  reset: boolean,
  mobile: boolean,
}
function ProgressBar({ duration, reset, mobile, }: IProgressBarProps) {
  const { Colors, Spacing, } = useSelector((state: IStores) => state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const animatedWidth = useRef(new Animated.Value(0)).current

  const startAnimation = useCallback(() => {
    animatedWidth.setValue(0)
    const animation = Animated.timing(animatedWidth, {
      toValue: 1,
      duration: duration,
      useNativeDriver: false,
      easing: Easing.inOut(Easing.ease),
    })
    animation.start()
  }, [duration, animatedWidth])

  useEffect(() => {
    startAnimation();
  }, [duration, reset, startAnimation])

  return (
    <View style={{width: '100%', flexDirection: 'row', height: 10, backgroundColor: 'transparent', overflow: 'hidden',}}>
      <View style={{flex: 0, minWidth: Spacing.padding / (mobile ? 2 : 1), backgroundColor: Colors.white,}} />
      <View style={{flex: 1,}}>
        <Animated.View
          style={{
            width: animatedWidth.interpolate({ inputRange: [0, 1], outputRange: ['100%', '0%'], }),
            height: 9,
            backgroundColor: Colors.white,
            alignSelf: 'flex-end',
          }}
        />
      </View>
      <View style={{flex: 0, minWidth: Spacing.padding / (mobile ? 2 : 1), backgroundColor: Colors.white,}} />
    </View>
  )
}

export default ProgressBar

import React, { } from 'react'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import { gradient } from '../../utils/constants'

interface IBlockBoxProps {
  children?: React.ReactNode,
  masterRef?: any,
}
function BlockBox({ children, masterRef, }: IBlockBoxProps) {
  const { School, } = useSelector((state: IStores) => state.systemStore)

  return (
    <div style={{
      position: 'absolute', width: '100%', height: '100%', minHeight: 600,
      // background: 'radial-gradient(circle at 30% 30%, rgba(0,114,253,255) 0%, rgba(77, 77, 255, 1) 50%',
      background: 'radial-gradient(1224px at 10.6% 8.8%, rgb(255, 255, 255) 0%, rgb(153, 202, 251) 100.2%)',
    }}>
      <img
        src={School.gradient || gradient}
        style={{
          position: 'absolute', zIndex: -1, width: '100%', height: '100%',
          objectFit: 'cover',
        }}
      />

      <div ref={masterRef} style={{position: 'absolute', zIndex: 0, width: '100%', height: '100%', overflowY: 'scroll', scrollBehavior: 'smooth',}}>
        {children}
      </div>
    </div>
  )
}

export default BlockBox

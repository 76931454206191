import React, { useEffect, useState } from 'react'
import { View, DimensionValue, Image, ImageSourcePropType, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'
import Text from '../text'

interface Qrs { [key: string]: ImageSourcePropType, }
const qrs: Qrs = {
  default: require(`../../assets/media/qr.png`),
  alex: require(`../../assets/media/qr-alex.png`),
  nico: require(`../../assets/media/qr-nico.png`),
}

interface IRefererBlockProps {
  order: number,
  mobile: boolean,
}
function RefererBlock({ order, mobile, }: IRefererBlockProps) {
  const { Colors, Fonts, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)

  const [referer, setReferer] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  }, [])

  const qr = referer ? qrs[referer] || qrs.default : qrs.default

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%',}}>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',}}>
          <Text style={{fontSize: Fonts.xxl, fontWeight: Fonts.middleWeight, marginBottom: 8, color: Colors.white,}}>Streetpass</Text>

          <Image
            source={qr}
            style={{width: 256, height: 256,}}
          />

          {/* <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, marginTop: 8, color: Colors.white,}}>Your new favorite dating app.</Text> */}
        </View>

        <View style={{position: 'absolute', width: '100%', justifyContent: 'center', alignItems: 'center', bottom: 8,}}>
          <Text style={{fontSize: 12, fontWeight: Fonts.featherWeight, color: Colors.white,}}>© {new Date().getFullYear()} Streetpass | Kite App Inc | All rights reserved</Text>
        </View>
      </View>
    </>
  )
}

export default RefererBlock

import React, { } from 'react'
import { TouchableOpacity, } from 'react-native'
import BlockBox from '../components/blocks/BlockBox'
import Text from '../components/text'
import { useNavigate } from 'react-router-dom'
import { Screens } from '../App'

function NotFound() {
  const navigate = useNavigate()

  return (
    <>
      <BlockBox>
        <TouchableOpacity
          onPress={() => navigate(Screens.Home)}
          style={{flex: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',}}
        >
          <Text style={{color: 'white', fontSize: 64,}}>404</Text>
        </TouchableOpacity>
      </BlockBox>
    </>
  )
}

export default NotFound

export const compact = '/assets/media/compact.png'
export const thumbnail = '/assets/media/thumbnail.png'
export const video = 'https://kite-case.s3.us-east-2.amazonaws.com/landing-c.mp4'
export const gif = 'https://kite-case.s3.us-east-2.amazonaws.com/landing-a.gif'
export const email = 'info@streetpass.app'
export const appStoreListing = 'https://apps.apple.com/app/6476482031'

export interface ISchoolConfig {
  logo: any,
  logoAlt: any,
  gradient: any,
  tone: string,
  highlight: string,
  headlines: Array<string>,
}

export const schoolConfigs: Record<string, ISchoolConfig> = {
  ubc: {
    logo: require('../assets/media/ubc.png'),
    logoAlt: require('../assets/media/ubc-alt.jpg'),
    gradient: require('../assets/media/gradient.jpg'),
    tone: '#0055B7',
    highlight: '#FFEA00',
    headlines: [
      'Find your Rose Garden romance.\nStreetpass: where UBC hearts connect.',
      'Missed sparks at the Nest?\nLet Streetpass be your second chance!',
      'Skip the line at Blue Chip, not your chance at love.\nDiscover Streetpass.',
      'From Buchanan to Biolabs, make every walk to class count with Streetpass.',
      'Thunderbird catching your eye?\nCatch them for real on Streetpass.',
      'Wreck Beach wasn\'t the only stunning view today.\nFind them on Streetpass!',
      'Is your perfect match in the Irving K. stacks?\nStreetpass knows.',
      'Beat the UBC bus rush—find a study date on your route with Streetpass.',
      'Not just another face in the lecture hall.\nSay hello with Streetpass.',
      'Sauder saw a spark?\nMix business and pleasure on Streetpass.',
      'Engineering connections stronger than the EUS Bridge.\nTry Streetpass.',
      'Missed a connection at the Totem Park Dining Hall?\nStreetpass has got you covered.',
      'Forget the fog, your love forecast is clear with Streetpass at UBC.',
      'AMS event alone?\nNext time, bring a date from Streetpass.',
      'From one Thunderbird to another, let Streetpass wingman your love life.',
      'Cramming in Koerner?\nDecompress with a date, thanks to Streetpass.',
      'Your study buddy in Scarfe could be more than a classmate with Streetpass.',
      'Caught a cute smile at Chan Centre?\nEncore with Streetpass.',
      'Love at first hike?\nMeet them at Pacific Spirit Park with Streetpass.',
    ],
  },
  sfu: {
    logo: require('../assets/media/sfu.png'),
    logoAlt: require('../assets/media/sfu-alt.png'),
    gradient: require('../assets/media/gradient.jpg'),
    tone: '#e7335b',
    highlight: '#e7335b',
    headlines: [
      'Harbour Centre views, Harbour Centre crushes.\nConnect on Streetpass.',
      'From the steps of SFU to the streets of Gastown, find your match with Streetpass.',
      'Missed a connection in the crowded halls of Segal?\nStreetpass is here to help.',
      'Your love story could be a SkyTrain ride away.\nDiscover them on Streetpass.',
      'Library silent zones can\'t silence the chemistry.\nFind your whisper-quiet match on Streetpass.',
      'Forget the rain at SFU Burnaby; spark something sunny on Streetpass.',
      'Woodward\'s might host your next lecture, but Streetpass hosts your next date.',
      'Studying downtown?\nBreak from the books and meet someone new with Streetpass.',
      'Coffee at West Hastings?\nMake it a date with Streetpass.',
      'Turn a chance glance at Goldcorp into a golden connection with Streetpass.',
      'From film night at Djavad Mowafaghian to real romance—only on Streetpass.',
      'Your study spot in Belzberg Library could be two seats away from a date. Check Streetpass.',
      'Missed a spark at SFU Contemporary Arts?\nRekindle it on Streetpass.',
      'Don\'t let foggy days blur your love prospects.\nClear up your romantic outlook with Streetpass at SFU.',
      'Art, architecture, and attraction—find all three in Vancouver\'s heart with Streetpass.',
      'Connect your circuits at Applied Sciences.\nStart with a spark on Streetpass.',
      'Caught eyeing someone at SFU Public Square?\nIt\'s time to Streetpass.',
      'A ride down Burnaby Mountain need not be lonely—find your companion on Streetpass.',
      'Meet your match faster than you can walk from Harbour Centre to Waterfront—only on Streetpass.',
    ],
  },
  uvic: {
    logo: require('../assets/media/uvic.png'),
    logoAlt: require('../assets/media/uvic-alt.png'),
    gradient: require('../assets/media/gradient.jpg'),
    tone: '#005493',
    highlight: '#ff5484',
    headlines: [
      'From Mystic Market to a magical moment.\nFind love on Streetpass.',
      'Lost in the Ring Road maze?\nFind your way to someone\'s heart with Streetpass.',
      'Finnerty\'s coffee pairs best with charming company.\nMeet them on Streetpass.',
      'Missed a spark in McPherson Library?\nRekindle it with Streetpass.',
      'Catching eyes in CARSA?\nConvert glances into plans with Streetpass.',
      'Not all the stars are at the Observatory.\nSpot your celestial match on Streetpass.',
      'Cadboro Bay outings are better with two.\nFind your beach buddy on Streetpass.',
      'Gardens are for wandering, hearts are for wondering.\nConnect on Streetpass.',
      'Bus loop missed connection?\nCatch them next time with Streetpass.',
      'Your study break at BiblioCafé could brew more than coffee with Streetpass.',
      'Turn a stroll through Alumni Chip Trail into a romantic walk with Streetpass.',
      'Engineering love connections stronger than the EOW building. Find them on Streetpass.',
      'UVIC\'s green spaces are perfect for blue-sky thinking and new romances. Discover on Streetpass.',
      'From lecture halls to Lampson streets, your next crush could be a click away on Streetpass.',
      'Biomedical? Environmental?\nYour field of study isn\'t the only match to make at UVIC with Streetpass.',
      'Missed a cute smile during class in Elliot?\nSmile back on Streetpass.',
      'Victoria\'s secrets aren\'t just scenic spots.\nUncover connections on Streetpass.',
      'Put your heart into it as much as your studies.\nFind a match on Streetpass.',
      'A quick bite at Mystic Market could lead to sweet talks through Streetpass.',
    ],
  },
}

export const schools = Object.keys(schoolConfigs)

export const gradient = require('../assets/media/gradient.jpg')
export const headlines = [
  'Not just another dating app.\nStreetpass connects you with the people you see every day and don\'t even notice.',
  'Real connections in real time.\nOnly with real people.',
  'Serendipity is not just a city in Tanzania. Start streetpassing today.',
  'There\'s always a second chance for first impressions.',
  'Every person you cross paths with could be the beginning of a new story.',
  'Missed a connection so you wouldn\'t miss the bus?\nStreetpass has got you covered.',
  'They never seat you next to the right person on flights. But with Streetpass, that\'s alright.',
  'Love is blind? Sometimes it just needs a second look.',
  'Remember that stranger that smiled at you?\nThey\'re not a stranger anymore.\nYou\'re welcome.',
  'Streetpass turns "What if?" into "Let\'s find out!"',
  'Because "missed connection" sounds better than "I stalked you on 5 different dating apps".\nTry Streetpass instead.',
  'From eye contact to contact list. Streetpass makes every awkward glance count.',
  'With Streetpass, love is just around the corner.',
  'Love\'s not dead. It just needed a better GPS.',
  'Sometimes your soulmate is just bad at directions.',
  'The best story starts with "So I streetpassed this person..."',
  'Romeo & Juliet had a balcony. You have Streetpass.',
  'Turn "I should have talked to them" into "Glad I downloaded Streetpass".',
  'Cinderella left a shoe. You left without a clue. Find your fairytale on Streetpass!',
  'Transform "What ifs" into "Remember whens".',
  'Roses are red. Violets are blue.\nMissed a connection?\nStreetpass finds them for you!',
  'Because happily ever after starts with "Hey, have we met before?"',
  'Not all treasure is buried. Some walks right past you every day.',
  'Lost the number but not the spark? Ignite it again with Streetpass!',
  'Cupid\'s arrow not hitting it\'s target for you?\nMaybe it was just missing GPS.',
  'It\'s like having a rewind button for fate. Play back those missed moments with Streetpass.',
  'Who says lightning doesn\'t strike twice? Rekindle sparks with Streetpass.',
]

import React, { useEffect, useRef, } from 'react'
import { View, DimensionValue, Image, TouchableOpacity, Dimensions, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import { thumbnail, video, gif, } from '../../utils/constants'
import Text from '../text'
// import { ReactComponent as StreetpassIcon, } from '../../assets/icons/streetpass-alt.svg'
import { ReactComponent as ChevronDownIcon, } from '../../assets/icons/chevron-down-filled.svg'

interface IVideoBlockProps {
  order: number,
  mobile: boolean,
  masterRef: any,
}
function VideoBlock({ order, mobile, masterRef, }: IVideoBlockProps) {
  const { Colors, Fonts, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const videoRef = useRef<HTMLVideoElement>(null)
  if (videoRef.current) {
    videoRef.current.defaultMuted = true
    videoRef.current.muted = true
    videoRef.current.playsInline = true
    videoRef.current.autoplay = true
    videoRef.current.loop = true
    videoRef.current.preload = 'auto'
  }

  // useEffect(() => {
  //   if (videoRef.current) videoRef.current.play().catch((error) => console.log('Autoplay error', error))
  //   // else setTimeout(() => videoRef.current?.play().catch((error) => console.log('Autoplay error', error)), 2000)
  // }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
      videoRef.current.playsInline = true
      videoRef.current.autoplay = true
      videoRef.current.loop = true
      videoRef.current.preload = 'auto'
    }

    const removeEventListeners = () => {
      document.removeEventListener('touchstart', tryPlayVideo)
      document.removeEventListener('scroll', tryPlayVideo)
      document.removeEventListener('click', tryPlayVideo)
      document.removeEventListener('keypress', tryPlayVideo)
      document.removeEventListener('mousemove', tryPlayVideo)
      window.removeEventListener('focus', tryPlayVideo)
      window.removeEventListener('load', tryPlayVideo)
    }

    const tryPlayVideo = () => {
      if (videoRef.current && videoRef.current.readyState >= 3) {
        videoRef.current.play().then(() => console.log('Playing video')).catch((error) => console.log('Autoplay error', error))
        removeEventListeners()
      }
    }

    tryPlayVideo()

    document.addEventListener('touchstart', tryPlayVideo)
    document.addEventListener('scroll', tryPlayVideo)
    document.addEventListener('click', tryPlayVideo)
    document.addEventListener('keypress', tryPlayVideo)
    document.addEventListener('mousemove', tryPlayVideo)
    window.addEventListener('focus', tryPlayVideo)
    window.addEventListener('load', tryPlayVideo)
    return () => removeEventListeners()
  }, [])

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, width: '100%', height: '100%',}}>
        <img
          src={thumbnail}
          alt={''}
          style={{position: 'absolute', zIndex: -2, width: '100%', height: '100%', objectFit: 'cover', backgroundColor: Colors.white,}}
        />
        {mobile ? <img
            src={gif}
            alt={''}
            style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', objectFit: 'cover',}}
          />
        : <video
            ref={videoRef}
            playsInline
            autoPlay
            muted
            loop
            preload={'auto'}
            style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', objectFit: 'cover',}}
          >
            <source
              src={video}
              type={'video/mp4'}
            />
          </video>
        }

        {/* <div
          dangerouslySetInnerHTML={{
            __html: `
              <video
                src="${require('../../assets/media/landing-c.mp4')}"
                crossorigin="anonymous"
                preload="auto"
                x-webkit-airplay="allow"
                autoplay
                muted
                playsinline
                loop
                style="width: 100%; height: 100%; position: absolute; z-index: -1, object-fit: cover;"
              ></video>
            `,
          }}
        /> */}

        <View style={{position: 'absolute', width: '100%', height: '15%', justifyContent: 'center', alignItems: 'center', marginTop: 32,}}>
          <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
            {/* <View style={{justifyContent: 'center', alignItems: 'center', marginRight: 8, marginBottom: 4,}}>
              <StreetpassIcon style={{width: Fonts.xxxl, height: Fonts.xxxl, fill: Colors.white,}} />
            </View> */}

            <Text style={{fontFamily: 'Fx', fontSize: Fonts.xxl, fontWeight: Fonts.heavyWeight, color: Colors.white, textAlign: 'center',}}>
              Streetpass
            </Text>

            {School.logo &&
              <View style={{aspectRatio: 1/1, borderRadius: 64, justifyContent: 'center', left: 16, bottom: 2, backgroundColor: School.highlight, overflow: 'hidden',}}>
                <Image
                  source={School.logo}
                  style={{width: Fonts.xxxl, height: '130%',}}
                  resizeMode={'contain'}
                />
              </View>
            }
          </View>

          {School.name ?
            <Text style={{fontFamily: 'Fx', fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white, textAlign: 'center',}}>
              The dating app for <Text style={{color: School.highlight,}}>{School.name.toUpperCase()}</Text> students.
            </Text>
          : <Text style={{fontFamily: 'Fx', fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white, textAlign: 'right',}}>
              For dating & friends.
            </Text>
          }
        </View>

        <TouchableOpacity
          onPress={() => masterRef.current.scrollTop = Dimensions.get('window').height > 600 ? Dimensions.get('window').height : 600}
          style={{position: 'absolute', alignSelf: 'center', bottom: 144,}}
        >
          <ChevronDownIcon style={{width: mobile ? Fonts.xxxxl : Fonts.xxxl, height: mobile ? Fonts.xxxxl : Fonts.xxxl, fill: Colors.white,}} />
        </TouchableOpacity>
      </View>
    </>
  )
}

export default VideoBlock
